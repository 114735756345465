.Slider-Container {
    position: relative;
    overflow: hidden;
    max-width: 1500px;
    margin: 0 auto;
    border-radius: 5px;
}

.Slider-Slide-Container {
    position: relative;
    height: 100%;
    height: 505px;
}

.Slider-Image-Container {
    position: relative;
    height: 505px;
    width: 50%;
    display: inline-block;
}

.Slider-Image {
    position: absolute;
    height: 800px;
}

.Slider-Content-Container {
    position: relative;
    height: 505px;
    width: 50%;
    display: inline-block;
    background-color: #fff;
}

.Slider-Content-Title {
    position: absolute;
    color: #009fae;
    text-align: center;
    top: 50px;
    font-size: 36px;
    padding: 50px;
    opacity: 1;
    transition: .3s opacity ease;
}

.Slider-Content-Body {
    position: absolute;
    bottom: 60px;
    padding: 50px;
    text-align: justify;
    color: #009fae;
    opacity: 1;
    font-size: 18pt;
    transition: .3s opacity ease;
}

.Slider-Content-Fade {
    opacity: 0;
}

.Slider-Content-Button {
    position: absolute;
    bottom: -20px;
    width: 135px;
    text-align: center;
    padding-top: 8px;
    padding-bottom: 4px;
    background-color: #fff;
    border-radius: 5px;
    cursor: pointer;
    color: #009fae;
    transition: .25s all ease;
}

.Slider-Content-Icon {
    position: absolute;
    bottom: -20px;
    right: 40px;
    width: 30px;
    height: 30px;
    cursor: pointer;
    color: #fff;
    transition: .25s text-shadow ease;
}

.Slider-Content-Icon:hover {
    text-shadow: 0 0 15px #fff;
}

.Slider-Content-Button:hover {
    box-shadow: 0 0 15px #ccc;
}

.Slider-Control-Container {
    width: 100%;
    background-color: #e5e7e9;
    height: 61px;
    position: relative;
    top: 0;
}

.Slider-Control-Controls-Container {
    position: relative;
    top: 10px;
    left: 42%;
}

.Slider-Control-Controls-Arrow-Container {
    display: inline-block;
    margin: 0 25px;
    height: 50px;
    width: 50px;
    background-color: #fff;
    border-radius: 50%;
}

.Slider-Control-Controls-Arrow {
    font-size: 40px;
    color: rgb(0,159,174);
    transition: .25s color linear;
}

.Slider-Control-Controls-Arrow:hover {
    font-size: 40px;
    color: #ccc;
}

.Slider-Control-Controls-Arrow-Left {
    margin-left: 8px;
}

.Slider-Control-Controls-Arrow-Right {
    margin-left: 12px;
}

.Slider-Control-Location-Container {
    display: inline-block;
    width: auto;
    text-align: center;
    height: 40px;
    background-color: #fff;
    border-radius: 50px;
}

.Slider-Control-Location-Inactive {
    display: inline-block;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background-color: #d7dbdd;
}

.Slider-Control-Location-Active {
    display: inline-block;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background-color: #d7dbdd;
}

