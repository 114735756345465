.OPD-Container {
    width: 100%;
    height: calc(99vh - 75px);
    background-color: #fff;
    padding: 5px;
    margin-top: -22px;
    overflow-y: auto;
    overflow-x: hidden;
}

.OPD-Top-Header-Container {
    width: calc(100% + 40px);
    height: 25px;
    display: inline-block;
    background-color: #f7f7f7;
    border-bottom: 1px solid #cacbc6;
    color: #636363;
    font-size: 9pt;
    padding-top: 4px;
    padding-left: 15px;
    padding-right: 15px;
    margin-left: -20px;
}

.OPD-Header-Container {
    width: 100%;
    height: 75px;
    display: inline-block;
    font-size: 10pt;
    padding-top: 25px;
}

.OPD-Menu-Header-Container {
    width: 100%;
    height: 45px;
    padding-top: 10px;
    display: inline-block;
    color: #1477D7;
    font-size: 14pt;
    border-top: 1px solid #e5e3e0;
    border-bottom: 1px solid #e5e3e0;
}

.OPD-Header-Item {
    
}

.OPD-Header-Item-Left {
    float: left;
    margin-right: 20px;
}

.OPD-Header-Item-Right {
    float: right;
    margin-left: 20px;
}
.OPD-Header-Img {
    width: 200px;
}

.OPD-Member-ID-Container {
    width: 140px;
    height: 30px;
    border: 1pt solid #636363;
    padding: 6px;
    border-radius: 5px;
    display: inline-block;
    font-size: 9pt;
    cursor: pointer;
}

.OPD-Member-ID-Container:hover {
    border: 1pt solid #1477D7;
    background-color: #e5e3e0;
    transition: .3s all ease;
}

.OPD-Message-Container {
    width: 140px;
    height: 30px;
    border: 1pt solid #348721;
    padding: 4px;
    border-radius: 5px;
    color: #348721;
    text-align: center;
    cursor: pointer;
}

.OPD-Message-Container:hover {
    border: 1pt solid #348721;
    background-color: #348721;
    color: #fff;
    box-shadow: 0 0 5px #000;
    font-weight: bold;
    transition: .3s all ease;
}

.OPD-Account-Container {
    width: 140px;
    height: 30px;
    border: 1pt solid #348721;
    background-color: #348721;
    padding: 4px;
    border-radius: 5px;
    color: #fff;
    text-align: center;
    cursor: pointer;
}

.OPD-Account-Container:hover {
    border: 1pt solid #164e08;
    background-color: #164e08;
    color: #fff;
    box-shadow: 0 0 5px #000;
    font-weight: bold;
    transition: .3s all ease;
}

.OPD-Subject-Header-Container {
    width: 100%;
    height: 80px;
    padding-top: 25px;
    font-size: 16pt;
    color: #0f5499;
    border-bottom: 1px solid #e5e3e0;
    margin-bottom: 20px;
}

.OPD-Menu-Need-Help {
    color: #9b26b6
}

.OPD-Search-Criteria-Container {
    width: 100%;
    display: inline-block;
    font-size: 12pt;
    margin-bottom: 10px;
}

.OPD-Search-Criteria-Label {
    float: left;
    font-weight: bold;
    margin-right: 15px;
}

.OPD-Search-Criteria-Item-Container {
    float: left;
    margin-right: 10px;
}

.OPD-Search-Criteria-Item-Label {
    float: left;
    margin-right: 5px;
}

.OPD-Search-Criteria-Item-Value {
    float: left;
    font-style: italic;
}

.OPD-Search-Buttons-Container {
    width: 100%;
    display: inline-block;
}

.OPD-Search-Button-Container {
    width: 180px;
    height: 40px;
    padding: 4px;
    border-radius: 5px;
    text-align: center;
    padding-top: 10px;
    cursor: pointer;
}

.OPD-Search-New-Button {
    background-color: #fff;
    border: 1pt solid #1477d7;
    color: #1477d7;
    text-align: center;
    float: right;
    cursor: pointer;
}

.OPD-Search-New-Button:hover {
    background-color: #1477d7;
    color: #fff;
    transition: .3s all ease;
}

.OPD-Search-Refine-Button {
    background-color: #1477d7;
    border: 1pt solid #1477d7;
    color: #fff;
    text-align: center;
    float: left;
    cursor: pointer;
}

.OPD-Search-Refine-Button:hover {
    background-color: #15508b;
    color: #fff;
    transition: .3s all ease;
}

.OPD-Search-Form-Container {
    width: 98%;
    margin-left: 1%;
    transition: .5s height ease;
    background-color: rgb(177, 212, 247);
    border-radius: 5px;
    padding-left: 5px;
    margin-bottom: 15px;
}

.OPD-Search-Form-New-Container {
    width: 90%;
    margin-left: 5px;
    transition: .5s height ease;
    background-color: #fff;
    border-radius: 5px;
}

.OPD-Search-Form-Row {
    width: 100%;
    display: inline-block;
}

.OPD-Search-Form-Item {
    float: left;
    margin-right: 15px;   
    margin-bottom: 15px;  
    height: 80px;
    min-width: 30%;
}

.OPD-Search-Form-Show {
    height: 700px;
}

.OPD-Search-Form-Hide {
    height: 1px;
}

.OPD-Results-Container {
    width: 98%;
    margin-left: 1%;
}

.OPD-Result-Row {
    padding: 25px;
    width: 100%;
    display: inline-block;
    border-bottom: 1px solid #e5e3e0;
}

.OPD-Result-Column {
    float: left;
    margin-right: 25px;
    width: calc(25% - 33px);
}

.OPD-Result-Column-Avatar {
    font-size: 128pt;
    color: #fff;
    padding-left: 25px;
    padding-top: 3px;
}

.OPD-Result-Column-Avatar-Container {
    background-color: #1477d7;
    height: 200px;
    width: 200px;
    border-radius: 50%;
}

.OPD-Result-Rating-Row {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 28px;
    text-align: center;
    font-size: 12pt;
}

.OPD-Result-Rating-Star {
    color: #ccc;
    margin-right: 5px;
}

.OPD-Result-Rating-Star-Checked {
    color: goldenrod;
    margin-right: 5px;
}

.OPD-Result-Row-Offset {
    background-color: rgb(229, 237, 252);
    border-radius: 5px;
}

.OPD-Result-Title {
    width: 100%;
    cursor: pointer;
    color: #1477d7;
    text-decoration: underline;
    font-size: 14pt;
}

.OPD-Result-Title:hover {
    text-decoration: none;
}

.OPD-Result-Practice {
    width: 100%;
    font-size: 12pt;
    cursor: pointer;
}

.OPD-Result-Practice-Type {

}

.OPD-Result-Distance {
    font-weight: bold;
    font-size: 12pt
}

.OPD-Result-Address-Line {
    width: 100%;
    font-size: 12pt;
}

.OPD-Result-Phone-Line {
    width: 100%;
    display: inline-block;
    font-size: 11pt;
}

.OPD-Result-Phone-Label {
    float: left;
    font-weight: bold;
}

.OPD-Result-Phone-Value {
    float: left;
    margin-left: 5px;
}

.OPD-Result-Website {
    width: 100%;
    cursor: pointer;
    color: #1477d7;
    text-decoration: underline;
    font-size: 12pt;
}

.OPD-Result-Website:hover {
    text-decoration: none;
}

.OPD-Search-Criteria-Results {
    width: 100%;
    margin: 15px 0;
    font-size: 18pt;
}

.OPD-Provider-List-Container {
    position: relative;
    width: 490px;
    top: -5px;
    left: 0px;
    z-index: 1;
    background-color: rgb(229, 237, 252);
    border-radius: 5px;
    padding: 5px;
    box-shadow: 5px 5px 8px #000;
}

.OPD-Provider-List-Section-Container {
    border-bottom: 1px solid #fff;
    border-top: 1px solid #fff;
}

.OPD-Provider-List-Section-Row {
    width: 100%;
    color: #000;
}

.OPD-Provider-List-Section-Title {
    font-size: 9pt;
    font-weight: bold;
    width: 100%;
    text-align: center;
}

.OPD-Provider-List-Section-Value {
    font-size: 11pt;
    cursor: pointer;
}

.OPD-Provider-List-Section-Value:hover {
    font-weight: bold;
    color: #1477d7;
}

.OPD-Provider-Container {
    width: 100%;
}

.OPD-Provider-Details-Container {
    width: 100%;
}

.OPD-Provider-Details-Row {
    width: 100%;
    display: inline-block;
    margin-bottom: 10px;
    padding: 15px;
}

.OPD-Provider-Details-Row-Flex {
    width: 90%;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-bottom: 10px;
    padding: 15px;
    height: 400px;
    overflow-y: auto;
}

.OPD-Details-Column {
    float: left;
    max-width: 190px;
    padding: 5px;
}

.OPD-Provider-Details-Item {
    width: 400px;
    height: 225px;
    border: 2px solid #85bdf5;
    margin: 15px;
    border-radius: 5px;
    padding: 5px;
    color: #fff;
    background-color: #85bdf5;
}

.OPD-Provider-Button {

}

.OPD-Provider-Button-Left {
    float: left;
    margin-right: 10px;
}

.OPD-Provider-Button-Right {
    float: right;
    margin-left: 10px;
}

.OPD-Provider-Details-Back {
    color: #1477d7;
    font-size: 11pt;
    border: 1pt solid #1477d7;
    border-radius: 5px;
    width: 145px;
    height: 32px;
    padding: 5px;
    cursor: pointer;
}

.OPD-Provider-Details-Back:hover {
    box-shadow: 0 0 5px #000;
    font-weight: bold;
}

.OPD-Result-Book-Link {
    width: 140px;
    height: 30px;
    border: 1pt solid #348721;
    background-color: #348721;
    padding: 4px;
    border-radius: 5px;
    color: #fff;
    text-align: center;
    cursor: pointer;
}

.OPD-Result-Book-Link:hover {
    border: 1pt solid #164e08;
    background-color: #164e08;
    color: #fff;
    transition: .3s all ease;
    box-shadow: 0 0 5px #000;
    font-weight: bold;
}

.OPD-Provider-Details-Title {
    width: 100%;
    font-size: 14pt;
    font-weight: bold;
    border-bottom: 2px solid #85bdf5;
}

.OPD-Provider-Details-Address {
    font-size: 14pt;
}

.OPD-Provider-Details-Facility {
    width: 100%;
    font-size: 12pt;
    font-weight: bold;
    border-bottom: 1px solid #fff;
}

.OPD-Provider-Details-Facility-Item {
    width: 100%;
    font-size: 11pt;
    font-weight: bold;
}

.OPD-Slots-List-Container {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    flex-wrap: wrap;
    border: 1pt solid #1477d7;
    border-radius: 5px;
}

.OPD-Slots-List-Item {
    width: 120px;
    height: 40px;
    border: 1pt solid #348721;
    background-color: #348721;
    padding: 4px;
    border-radius: 5px;
    color: #fff;
    text-align: center;
    cursor: pointer;
    margin: 5px;
}

.OPD-Slots-List-Item:hover {
    border: 1pt solid #164e08;
    background-color: #164e08;
    color: #fff;
    transition: .3s all ease;
    box-shadow: 0 0 5px #000;
    font-weight: bold;
}

.OPD-Slots-Container {
    width: 100%;
    height: 400px;
    overflow-y: auto;
    overflow-x: hidden;
}

.OPD-Slots-List-Container {
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
}

.OPD-Slots-Row {
    display: inline-block;
    width: 100%;
    margin-bottom: 10px;
}

.OPD-Slots-Row-Item-Left {
    float: left;
}

.OPD-Slots-Row-Item-Right {
    float: right;
}

.OPD-Conf-Container {
    width: 100%;
    margin-top: 10px;
    padding: 10px;
}

.OPD-Conf-Row {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.OPD-Conf-Status {
    margin: 0 auto;
    width: 300px;
}

.OPD-Conf-Status-Icon {
    width: 60px;
    margin: 0 auto;
    font-size: 48pt;
    font-weight: bold;
    color: #1477d7;
}

.OPD-Conf-Status-Loading {
    
}

.OPD-Conf-Status-Msg {
    width: 100%;
    font-size: 14pt;
    font-weight: normal;
    color: #1477d7;
    text-align: center;
}