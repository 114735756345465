.Home .lander {
    padding: 40px 0;
    text-align: center;
  }
  
  .Home .lander h1 {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
  }
  
  .Home .lander p {
    color: #fff;    
  }

  .Home {
    background-color: #009fae;
    height: 100vh;
  }