.Start-Scenes-Container {
    position: absolute;
    width: 90%;
    top: 75px;
    transition: .25s all ease-in-out;
    margin-bottom: 40px;
}

.Start-Scenes-Container-Right {
    left: 250px;
}

.Start-Scenes-Container-Center {
    left: 5%;
}

.Start-Scenes-Container-Left {
    left: -5%;
}

.Start-Info-Container {
    position: absolute;
    top: 100px;
    height: auto;
    min-height: 250px;
    width: 300px;
    transition: .25s all ease-in-out;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border: 1px solid #000;
    padding: 5px;
    background-color: #fff;
}

.Start-Info-Tab {
    position: absolute;
    top: 25px;
    right: -25px;
    width: 44px;
    height: 44px;
    cursor: pointer;
    z-index: 2;
    border-radius: 50%;
    border: 1px solid #000;
    text-align: center;
    box-shadow: 5px 1px 8px #000;
    background-color: #fff;
    font-size: 24pt;
}

.Start-Info-Container-Open {
    left: 0;
    box-shadow: 5px 5px 8px #000;
    z-index: 2;
}

.Start-Info-Container-Close {
    left: -300px;
    box-shadow: none;
}

.Start-FHIR-Container {
    position: fixed;
    top: 100px;
    height: auto;
    min-height: 250px;
    width: 300px;
    transition: .25s all ease-in-out;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border: 1px solid #000;
    padding: 5px;
    background-color: #fff;
}

.Start-FHIR-Tab {
    position: absolute;
    top: 25px;
    left: -25px;
    width: 44px;
    height: 44px;
    cursor: pointer;
    z-index: 2;
    border-radius: 50%;
    border: 1px solid #000;
    text-align: center;
    box-shadow: 5px 1px 8px #000;
    background-color: #fff;
    font-size: 24pt;
}

.Start-FHIR-Container-Open {
    left: calc(100% - 300px);
    box-shadow: -5px 5px 8px #000;
    z-index: 2;
}

.Start-FHIR-Container-Close {
    left: 100%;
    box-shadow: none;
}

.Start-FHIR-Body {
    max-height: 750px;
    padding: 5px;
    overflow: auto;
    color: #fff;
    background-color: #000;
}

.Start-Scene-Container {
    width: 275px;
    background-color: #dfeaea;
    border: 2pt solid #000;
    border-radius: 5px;
    height: 170px;
}

.Start-Scene-Container:hover {
    box-shadow: 0px 0px 15px #009fae;
    transition: .25s all ease;
}

.Start-Scene-Container-Selected {
    box-shadow: 0px 0px 15px #009fae;
    transition: .25s all ease;
}

.Start-Scenes-Row {
    display: flex;
    justify-content: space-evenly;
    margin: 100px 0;
}

.Start-Scene-Title {
    width: 100%;
    height: 40px;
    font-size: 11pt;
    font-weight: bold;
    text-align: center;
    background-color: #009fae;
    color: #fff;
    padding: 3px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    margin-bottom: 10px;
    cursor: pointer;
    display: inline-flex;
    justify-content: space-between;
}

.Start-Scene-Body {
    padding: 5px;
}

.Start-Scene-Actors {
    display: flex;
    justify-content: space-evenly;
}

.Start-Scene-Usecases {
    display: inline-block;
    justify-content: space-evenly;
}

.Start-Scene-Usecase {
    float: left;
    margin: 5px;
    width: 80px;
    height: 100px;
    border: 1px solid #000;
    border-radius: 5px;
    text-align: center;
    padding: 5px;
}

.Start-Scene-Usecase-Active {
    background-color: #fff;
    cursor: pointer
}

.Start-Scene-Usecase-Inactive {
    background-color: #ccc
}

.Start-Scene-Actor {
    width: 80px;
    height: 100px;
    border: 1px solid #000;
    border-radius: 5px;
    background-color: #fff;
    text-align: center;
    padding: 5px;
    cursor: pointer;
}

.Start-Scene-Actor-Icon {
    font-size: 32px;
    width: 100%;
}

.Start-Scene-Actor-Title {
    width: 100%;
    font-size: 9pt;
    font-weight: bold;
    color: #000;
}

.Start-Actor-Tasks {
    position: relative;
    width: 80px;
    top: 15px;
    left: -5px;
    z-index: 2;
    cursor: pointer;
}

.Start-Actor-Task {
    width: 100%;
    border: 1px solid #000;
    border-radius: 5px;
    font-size: 9pt;
    height: auto;
    margin-top: 3px;
    background-color: #fff;
    box-shadow: 4px 4px 8px #000;
    color: #009fae;
    padding: 2px;
}

.Start-FHIR-Refresh {
    width: 140px;
    text-align: center;
    height: 25px;
    padding-top: 3px;
    font-size: 12px;
    background-color: #ccc;
    color: #fff;
    border: 1pt solid #000;
    cursor: pointer;
    border-radius: 5px;
    font-weight: bold;
    display: inline-block;
}

.Start-FHIR-Refresh-Text {
    margin-left: 40px;
    float: left;
}

.Start-FHIR-Refresh:hover {
    box-shadow: 0 0 5px #000;
}

.Start-FHIR-Refresh-Spinning {
    float: left;
    margin-right: 7px;
    top: 2px;
    animation: spinx 1s infinite linear;
}

@keyframes spinx {
    from { transform: scale(1) rotate(0deg); }
    to { transform: scale(1) rotate(360deg); }
}
