.Dialog-Container {
    position: relative;
    top: 3vh;
    max-width: 75%;
    background-color: #fff;
    border: 1pt solid #262626;
    border-radius: 5px;
    box-shadow: 0 0 15px #262626;
    padding: 15px;
    margin: 0 auto;
    margin-bottom: 75px;
    transition: .25s all linear;
}

.Dialog-Container-First {
    position: relative;
    top: 3vh;
    max-width: 75%;
    background-color: #fff;
    border: 1pt solid #262626;
    border-radius: 5px;
    box-shadow: 0 0 15px #262626;
    padding: 15px;
    margin: 0 auto;
    transition: .25s all linear;
}

.Dialog-Container-Next {
    max-width: 75%;
    background-color: #fff;
    border: 1pt solid #262626;
    border-radius: 5px;
    box-shadow: 0 0 15px #262626;
    padding: 15px;
    margin: 1vh auto;
    opacity: 1;
    transition: .25s all linear;
}

.Dialog-Title {
    width: 100%;
    display: inline-block;
    font-weight: bold;
    font-size: 26pt;
    color: #009fae;
    text-align: center;
    margin-bottom: 10px;
}

.Dialog-Title i {
    margin-right: 15px;
    color: rgb(0, 159, 174);
}

.Dialog-Title img {
    margin-right: 15px;
    height: 50px;
}

.Selectable {
    cursor: pointer;
}

.Dialog-Center {
    margin: 0 auto;
}

.Dialog-Hide {
    opacity: 0;
}

.Dialog-Left {
    left: -5000px;
}

.Slots {
    display: inline-block;
}

.Slot {
    width: 200px;
    height: 50px;
    font-weight: bold;
    font-size: 18pt;
    border: 1px solid #008800;
    color: #008800;
    border-radius: 5px;
    text-align: center;
    margin: 10px;
    float: left;
    padding-top: 10px;
    cursor: pointer;
    transition: .25s all ease;
}

.Slot:hover {
    box-shadow: 0 0 15px #008800;
    border: 2px solid #008800;
}

.Slot-Selected {
    color: #fff;
    background-color: #008800;
}

.Provider-Selected {
    color: #fff !important;
    background-color: #008800 !important;
}

.Cal-Container {
    margin-left: 10px;
}

.Cal-Input {
    border-radius: 5px;
    padding: 5px;
    border-color: #008800;
    font-weight: bold;
}

.Cal {
    border-radius: 5px;
    font-weight: bold;
    font-size: 11pt !important;
}

.Confirm-Img-Container {
    width: 20%;
    margin: 0 auto;
}

.Confirm-Text-Container {
    width: 100%;
    padding: 15px;
    color: rgb(0, 159, 174);
    font-weight: bold;
    font-size: 18pt;
    text-align: center;
}

.Confirm-Appt-Title {
    width: 100%;
    color: #000;
    font-weight: bold;
    font-size: 12pt;
    text-align: center;
    margin-top: 10px;
}

.Confirm-Appt-Container {
    width: 100%;
    background-color: #ccc;
    margin: 15px auto;
    padding: 20px;
    font-size: 12pt;
    border-radius: 5px;
    text-align: center;
    max-height: 200px;
    overflow-y: auto;
}

.Error-Msg {
    width: 100%;
    color: #ff0000;
    font-style: italic;
    font-size: 10pt;
    margin-top: 15px;
    text-align: center;
}

.Done-Container {
    width: 100%;
    margin: 35px;
    padding: 20px;
    font-size: 12pt;
    border-radius: 5px;
    text-align: center;
}

.Waiting-Image {
    margin-left: 15px;
    color: rgb(0, 159, 174);
    animation: spin 1s infinite linear;
}

@keyframes Wait-Spin {
    from { transform: scale(1) rotate(0deg); }
    to { transform: scale(1) rotate(360deg); }
}

.Insurance-Row {
    width: 100%;
    display: inline-block;
    font-size: 12pt;
    text-align: left;
}

.Insurance-Key {
    width: 40%;
    color: #262626;
    font-weight: bold;
    float: left;
}

.Insurance-Value {
    width: 60%;
    color: #333;
    float: left;
}

.Insurance-Value-List {
    width: 100%;
}

.Interactions-Container {
    max-width: 75%;
    background-color: #fff;
    border: 1pt solid #262626;
    border-radius: 5px;
    box-shadow: 0 0 15px #262626;
    padding: 10px;
    margin: 1vh auto;
    transition: .25s all linear;
}

.Interactions {
    display: inline-block;
    margin: 0 20%;
}

.Interaction {
    width: 40px;
    height: 40px;
    font-weight: bold;
    font-size: 14pt;
    border: 1px solid #009fae;
    color: #009fae;
    border-radius: 5px;
    text-align: center;
    margin: 5px;
    float: left;
    padding-top: 8px;
    cursor: pointer;
    transition: .25s all ease;
}

.Interaction:hover {
    box-shadow: 0 0 15px #009fae;
    border: 2px solid #009fae;
}

.Interaction-Selected {
    color: #fff;
    background-color: #009fae;
}

.BlueButton-Container {
    width: 100%;
}

.BlueButton {
    width: 150px;
    height: 40px;
    font-weight: bold;
    font-size: 14pt;
    border: 1px solid #fff;
    color: #009fae;
    border-radius: 5px;
    text-align: center;
    margin: 5px;
    padding-top: 8px;
    cursor: pointer;
    background-color: #fff;
    transition: .25s all ease;
}

.BlueButton:hover {
    box-shadow: 0 0 15px #fff;
    border: 2px solid #009fae;
}

.CMS-Table-Container {
    position: relative;
    margin: 0 auto;
    width: 70%;
}
