.Appointments-Container {
    position: relative;
    top: 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    max-height: calc(100vh - 50px);
    overflow-y: auto;
}

.Appointments-Title {
    width: 100%;
    text-align: center;
    font-size: 16pt;
    font-weight: bold;
    color: #3771be;
}

.Appointment-Title-Container {
    width: 100%;
    font-size: 16pt;
    font-weight: bold;
    text-align: center;
    background-color: #3771be;
    color: #fff;
    display: inline-block;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
}

.Appointment-Title-Desc {
    
}

.Appointment-Title-ID {
    float: right;
}

.Appointment-Item-Container {
    border-radius: 5px;
    border: 1px solid #3771be;
    padding: 5px;
    margin: 10px;
    width: 400px;
    color: #3771be;
}

.Appointment-Item-Row {
    width: 100%;
    display: inline-block;
    margin-top: 3px;
    padding: 3px;
}

.Appointment-Item-Left {
    float: left;
    margin-right: 5px;
}

.Appointment-Item-Right {
    float: right;
    margin-left: 5px;
}

.Appointment-Item-Only {
    width: 100%;
    text-align: center;
}

.Appointment-Item {
    width: 100%;
    display: inline-block;
    margin-top: 3px;
}

.Appointment-Item-Label {
    width: 100%;
    font-size: 9pt;
    font-weight: bold;
}

.Appointment-Item-Value {
    width: 100%;
    font-size: 12pt;
    font-weight: bold;
}