.Appointment-Container {
    position: relative;
    height: 100%;
    width: 100%;
}

.Appointment-Form-Container {
    position: absolute;
    height: 550px;
    width: 600px;
    background-color: #fff;
    margin: 0 auto;
    top: 100px;
    left: 0; 
    right: 0; 
    margin-left: auto; 
    margin-right: auto;
    border: 1pt solid #b8b8b8;
    border-radius: 5px;
    box-shadow: 0 5px 15px #ccc;
    transition: .25s all linear;
}

.Appointment-Indicator-Container {
    position: fixed;
    bottom: 25px;
    height: 30px;
    left: 0; 
    right: 0; 
    margin-left: auto; 
    margin-right: auto;
    text-align: center;
}

.Appointment-Indicator {
    height: 25px;
    width: 25px;
    border: 1px solid #95989a;
    border-radius: 50%;
    display: inline-block;
    margin: 5px;
    cursor: pointer;
}

.Appointment-Indicator-Current {
    background-color: #009fae;
}

.Providers-Loading {
    margin-left: 7px;
    top: -5px;
    font-size: 24px;
    animation: loadingx 1s infinite linear;
}

@keyframes loadingx {
    from { transform: scale(1) rotate(0deg); }
    to { transform: scale(1) rotate(360deg); }
}