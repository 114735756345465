.App {
  padding-top: 15px;
  padding-left: 15px;
  padding-right: 15px;
}

.nav>li>a {
  position: relative;
  display: block;
  padding-left: 7px;
  padding-right: 7px;
}

.App-Faded {
  opacity: 0;
}

.App-Showing {
  opacity: 1;
}

.App .navbar-brand {
  font-weight: bold;
}

.App-Container {
  width: 100%;
  height: 100vh;  
}

.App-Loading {
  position: absolute;
  width: 100%;
  height: 100vh;
  background-color: #009fae;
}

.App-Loading-Bar {
  position: relative;
  margin-top: 50%;
  background-color: #fff;
  transition: .20s all ease-in
}

.App-Loading-Bar-Init {
  height: 5px;
  width: 0;
}

.App-Loading-Bar-Final {
  height: 5px;
  width: 100%;
}

.App-Loading-Bar-Post {
  margin-top: 0;
  width: 100%;
  height: 100vh;
}

.ECW-Item-Container {
  width: 100%;
  background-color: #fffafa;
  margin: 15px auto;
  padding: 20px;
  font-size: 12pt;
  border-radius: 5px;
  text-align: center;
}

.ECW-Item-Loading {
  font-size: 16pt;
  font-weight: bold;
  color: #009fae;
  width: 100%;
  text-align: center;
}

.ECW-Item-Working {
  margin-right: 7px;
  top: 2px;
  animation: spin 1s infinite linear;
}
