.Alert-Container {
    position: fixed;
    top: 120px;
    right: 0px;
    display: inline-block;
    z-index: 2;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.Alert-Main {
    float: left;
    width: 40px;
    cursor: pointer;
    padding: 5px;
    text-align: center;
    background-color: rgb(223, 18, 18);
    color: #fff;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border: 1px solid #fff;
    box-shadow: 3px 3px 8px #000;
}

.Alert-Open {
    float: left;
    background-color: rgb(243, 240, 240);
    box-shadow: 3px 3px 8px #000;
    transition: width .5s ease;
    height: 31px;
    margin-bottom: 20px;
}

.Alert-Cards {
    position: fixed;
    top: 155px;
    right: 0px;
    width: 370px;
    z-index: 2;
}

.Alert-Card {
    width: 350px;
    margin-top: 8px;
    margin-bottom: 7px;
    border: 1pt solid #ccc;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    background-color: #fff;
    padding: 5px;
    max-height: 400px;
    box-shadow: 3px 3px 8px #000;
    overflow-y: auto;
}

.Alert-Header {
    width: calc(100% - 5px);
    color: rgb(223, 18, 18)
}

.Alert-Card-Info {
    width: 100%;
}

.Alert-Card-Header {
    width: 100%;
    font-weight: bold;
}

.Alert-Card-Body {
    display: inline-block;
    width: 100%;
}

.Alert-Card-Body-Info {
    float: left;
    margin-left: 5px;
}

.Alert-Card-Title {
    width: 100%;
    font-weight: bold;
    display: inline-block;
    background-color: rgb(243, 240, 240);
    padding-top: 5px;
}

.Alert-Card-Item {
    float: left;
    margin-left: 5px;
}

.Alert-Clear {
    width: 25px;
    float: right;
    cursor: pointer;
}

.Alerts-Icon {
    animation: flash .9s infinite;
    cursor: pointer;
}

.Alerts-Icon-Red {
    animation: flash-red .9s infinite;
    cursor: pointer;
}

.Alerts-Notify {
    width: 100%;
    margin: 5px;
    padding: 5px;
    background-color: rgb(0, 159, 174);
    color: #fff;
    font-weight: bold;
    text-align: center;
    cursor: pointer;
    border-radius: 5px;
}

.Alerts-Notify:hover {
    border: 1px solid rgb(0, 159, 174);
    background-color: #fff;
    color: rgb(0, 159, 174);
    transition: .25s all ease;
    box-shadow: 0 0 8px rgb(0, 159, 174);
}

.Alert-Card-Section {
    width: 100%;
}

.Alert-Card-Row {
    width: 100%;
    margin-top: 3px;
}

.Alert-Card-Item-Left {
    float: left;
    margin-right: 5px;
}

.Alert-Card-Item-Right {
    float: right;
    margin-left: 5px;
}

.Alert-Card-Label {
    width: 100%;
    font-size: 9pt;
    font-weight: bold;
    color: #3771be;
}

.Alert-Card-Value {
    width: 100%;
    font-size: 11pt;
    font-weight: bold;
}

.Alert-Resource-Section {
    margin-top: 5px;
    margin-bottom: 5px;
}

.Alert-Resource-Section-Label {
    font-size: 12px;
    font-weight: bold;
    margin-top: 5px;
    color: #3771be;
}

.Alert-Resource-Section-Value {
    font-size: 16px;
    padding-top: 2px;
    font-weight: bold;
}

@keyframes flash {
    from {color: #fff; text-shadow: rgb(0, 159, 174)}
    50% {color: rgb(0, 159, 174); text-shadow: #fff}
    to {color: #fff; text-shadow: rgb(0, 159, 174)}
}
@keyframes flash-red {
    from {color: #fff; text-shadow: rgb(223, 18, 18)}
    50% {color: rgb(223, 18, 18); text-shadow: #fff}
    to {color: #fff; text-shadow: rgb(223, 18, 18)}
}
