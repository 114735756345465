.Dashboard-Card-Graph-Container-1 {
    width: 255px;
    height: 250px;
    float: left;
    margin-left: 15px;
    background-color: #eff6fd;
    border-radius: 5px;
    margin-top: 10px;
}

.Dashboard-Card-Graph-Container-2 {
    width: 255px;
    height: 250px;
    float: left;
    margin-left: 15px;
    margin-top: 10px;
    background-color: #d1e3fa;
    border-radius: 5px;
}

.Dashboard-Card-Graph-Container-3 {
    width: 255px;
    height: 250px;
    float: left;
    margin-left: 15px;
    background-color: #bbd5f7;
    border-radius: 5px;
    margin-top: 10px;
}

.Dashboard-Details-Graph-Title {
    width: 100%;
    height: 45px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    font-size: 16px;
    padding: 5px;
    color: #fff;
    background-color: #3771be;
    display: inline-block;
}

.Dashboard-Details-Graph-Title-Left {
    float: left;
    margin-right: 5px;
    margin-top: 5px;
}

.Dashboard-Details-Graph-Title-Right {
    float: right;
    margin-right: 5px;
    margin-top: 5px;
    font-weight: bold;
}

.Me-Container {
    width: 100%;
    height: 90vh;
    overflow-y: auto;
}

.Me-Section {
    position: relative;
    top: 3vh;
    background-color: #fff;
    border: 1pt solid #3771be;
    border-radius: 5px;
    box-shadow: 0 0 15px #262626;
    padding: 15px;
    transition: all .25s linear;
    margin-top: 25px;
    max-height: 400px;
    overflow-y: auto;
}

.Me-Section-Header {
    width: 100%;
    display: inline-block;
    font-weight: 700;
    font-size: 26pt;
    color: #009fae;
    text-align: center;
    margin-bottom: 10px;
}

.Me-Section-Header i {
    margin-right: 10px;
}

.Me-Section-Header img {
    margin-right: 10px;
    height: 35px;
}

.Me-Section-Body {
    width: 100%;
    background-color: #fff;
    padding: 20px;
    font-size: 12pt;
    border-radius: 5px;
    text-align: center;
    max-height: 300px;
    overflow-y: auto;
}

.Me-Section-Row {
    width: 100%;
    margin: 10px 0;
    padding: 5px;
}

.Me-Section-Graphs {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.Me-Demographics-Container {
    display: flex;
    justify-content: space-around;
}

.Rx-Plan-Container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.Rx-Plan-Title {
    width: 100%;
    display: inline-block;
    font-size: 18pt;
    font-weight: bold;
    color: #fff;
    background-color: #009fae;
    padding: 3px 5px;
    border-radius: 5px;
}

.Rx-Plan-Title-Plans {
    position: absolute;
    right: 40px;
    display: inline-block;
}

.Rx-Plan-Selector {
    float: left;
    margin-right: 5px;
    cursor: pointer;
}

.Rx-Plan-Selector:hover {
    text-shadow: 0 0 8px #000;
}

.Rx-Plan-Table-Container {
    width: 610px;
    margin: 10px;
    text-align: center;
    border: 1px solid #009fae;
    border-radius: 5px;
}

.Rx-Plan-Table-Title {
    width: 100%;
    border-bottom: 1px solid #009fae;
    font-size: 14pt;
    font-weight: bold;
    color: #009fae;
}

.Rx-Plan-Table-Header {
    width: 100%;
    display: inline-block;
    text-decoration: underline;
    font-size: 11pt;
    font-weight: bold;
    color: #009fae;
}

.Rx-Plan-Column-Header {
    width: 25%;
    float: left;
    text-decoration: underline;
    font-weight: bold;
    color: #009fae;
}

.Rx-Plan-Table-Row {
    width: 100%;
    display: inline-block;
}

.Rx-Plan-Table-Item {
    width: 25%;
    float: left;
}

.Me-Med {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.Me-Med-Card {
    width: 200px;
    border: 1px solid #009fae;
    border-radius: 5px;
    padding: 4px;
}

.Me-Med-Title {
    text-align: center;
    border-bottom: 1px solid #009fae;
    font-weight: bold;
    font-size: 12pt;
    color: #fff;
    background-color: #009fae;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.Me-Med-Row {
    width: 100%;
    height: 30px;
    display: inline-block;
}

.Me-Med-Item {

}

.Me-Med-Item-Title {
    color: #009fae;
    font-weight: bold;
    font-size: 11pt;
}

.Me-Med-Item-Value {
    font-size: 14pt;
}
