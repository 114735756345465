.MRP {
    position: relative;
    width: 100%;
}

.MRP-Container {
    position: relative;
    top: 3vh;
    max-width: 65%;
    background-color: rgb(131, 114, 114);
    border: 1pt solid #262626;
    border-radius: 5px;
    box-shadow: 0 0 15px #262626;
    padding: 15px;
    margin: 0 auto;
    opacity: 1;
    transition: .25s all linear;
}

.MRP-Results-Container {
    margin-top: 15px;
}

.MRP-Search-Container {
    width: 820px;
    margin: 0 auto;
    margin-top: 50px;
}

.MRP-Row {
    width: 100%;
    margin-top: 3px;
}

.MRP-Search-Label {
    font-size: 32px;
    color: #0FA1AC;
}

.MRP-Search-Label i {
    margin-left: 10px;
    font-size: 18pt;
    animation: spin2 1s infinite linear;
}

.MRP-Search-Outter {
    width: 820px;
    height: 40px;
    background-color: #fff;
    border: 1px solid #e5e7e9;
    border-radius: 5px;
    padding: 6px;
    font-size: 17px;
    color: #1a4255;
    display: inline-block;
}

.MRP-Search-Button {
    width: 48px;
    height: 38px;
    float: right;
    background-color: #0fa1ac;
    color: #fff;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    margin-top: -6px;
    margin-right: -6px;
    padding-top: 6px;
    padding-left: 8px;
    cursor: pointer;
}

.MRP-Search-Button:hover {
    background-color: rgb(83, 0, 83);
    transition: .25s all ease;
}

.MRP-Search-Button i {
    font-size: 28px;
}

.MRP-Search-Selector {
    width: 120px; 
    height: 26px;
    padding: 2px;
    border: 1px solid #e5e7e9;
    font-size: 14px;
    cursor: pointer;
    border-radius: 5px;
    float: left;
}

.MRP-Search-Selector:hover {
    border: 2px solid #e5e7e9;
    font-size: 14px;
}

.MRP-Search-Selector-Arrow {
    float: right;
    border: solid #8ba0aa;
    border-width: 0 3px 3px 0;
    padding: 3px;
    transform: rotate(45deg);
    margin: 3px;
}

.MRP-Search-Bar {
    float: left;
    margin-left: 10px;
    width: 630px;
    border: none;
}

.MRP-Search-Selections {
    position: absolute;
    width: 126px;
    height: auto;
    z-index: 2;
    padding: 2px;
    border: 1px solid #e5e7e9;
    background-color: #fff;
    border-radius: 5px;
    color: #394653;
    box-shadow: 0 3px 6px #455A64
}

.MRP-Search-Selections-Row {
    border-radius: 5px;
    width: 100%;
    cursor: pointer;
}

.MRP-Search-Selections-Row:hover {
    color: #fff;
    background-color: #394653;
    transition: .25s all ease;
}

.MRP-Results {
    width: 100%;
}

.MRP-Results-List {
    position: relative;
    width: 98%;
    margin: 0 auto;
    top: 0px;
    padding: 5px;
    padding-top: 15px;
    height: 100%;
    overflow-y: auto;
    height: calc(100vh - 230px)
}

.MRP-Result {
    width: 100%;
    border: 1pt solid #ced1d5;
    border-radius: 5px;
    box-shadow: 0 3px 6px #455a64;
    background-color: #fff;
    margin-top: 35px;
}

.MRP-Result-Title {
    position: relative;
    padding: 2px;
    font-weight: bold;
    margin-top: -25px;
    margin-left: 10px;
    font-size: 20pt;
    color: #fff;
    background-color: #0fa1ac;
    border-radius: 10px;
}

.MRP-Result-Date {
    float: right;
    margin-top: 5px;
    margin-right: 5px;
    font-size: 11pt;
    color: #394653;
    font-weight: bold;
}

.MRP-Result-Status {
    float: left;
    font-size: 36pt;
    margin-top: -30px;
    margin-left: 25px;
    color: #0fa1ac;
    width: 40px;
    text-shadow: 0 3px 6x #394653;
}

.MRP-Result-Row {
    margin-top: 20px;
    width: 100%;
    display: flex;
    padding-right: 8px;
}

.MRP-Result-Section {
    padding: 4px;
    text-align: left;
    color: #394653;
    border: 1pt solid #0fa1ac;
    border-top: none;
    float: left;
    border-radius: 5px;
    margin-left: 8px;
    margin-bottom: 5px;
    min-width: 150px;
}

.MRP-Result-Section-Title {
    position: relative;
    margin-top: -18px;
    margin-left: 5px;
    margin-right: 10px;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 5px;
    color: #0fa1ac;
}

.MRP-Result-Section-Label {
    font-size: 11px;
    font-weight: bold;
    margin-top: 5px;
}

.MRP-Result-Section-Value {
    font-size: 14px;
}

.CRD-Title {
    padding: 15px;
    background-color: #fff;
    font-size: 30pt;
    font-weight: bold;
    color: #262626;
    border-radius: 5px;
}

.CRD-Title i {
    margin-left: 10%;
    cursor: pointer;
}

.CRD-Title i:hover {
    text-shadow: 0 0 10px #009fae;
}

.CRD-Results-Container {
    width: 100%;
    border: 1px solid #009fae;
    margin: 15px auto;
    padding: 15px;
    font-size: 12pt;
    color: #009fae;
    border-radius: 5px;
    max-height: 500px;
    overflow-y: auto;
}

.CRD-Row {
    width: 100%;
    display: inline-block;
    font-size: 18pt;
    text-align: center;
    font-weight: bold;
    margin-bottom: 10px;
}

.CRD-Key {
    width: 40%;
    font-size: 14pt;
    float: left;
    text-align: left;
}

.CRD-Value {
    width: 60%;
    float: left;
    font-size: 14pt;
    font-weight: normal;
    text-align: left;
}

.Search-Container {
    position: absolute;
    width: 80%;
    left: 10%;
    background-color: #fff;
    border: 1px solid #000;
    border-radius: 5px;
    box-shadow: 5px 5px 20px #262626;
    opacity: 1;
    padding: 15px;
}

.Search-Row {
    width: 100%;
    display: inline-block;
    font-size: 16pt;
    text-align: left;
    font-weight: bold;
    margin-bottom: 10px;
    padding: 5px;
    cursor: pointer;
}

.Search-Row:hover {
    border: 1px solid #009fae;
    box-shadow: 0 0 10px #009fae;
}

.Search-Button-Container {
    width: 100%;
}

.Search-Button {
    width: 150px;
    height: 50px;
    font-weight: bold;
    font-size: 18pt;
    border-radius: 5px;
    text-align: center;
    float: right;
    padding-top: 5px;
    cursor: pointer;
    border: 1px solid #4d4d4d;
    color: #4d4d4d;
    transition: .25s all ease;
}

.Search-Button:hover {
    box-shadow: 0 0 15px #4d4d4d;
    background-color: #4d4d4d;
    color: #fff;
}


@keyframes spin2 {
    from { transform: scale(1) rotate(0deg); }
    to { transform: scale(1) rotate(360deg); }
}

.EDI-Search-Selector {
    width: 150px; 
    height: 26px;
    padding: 2px;
    border: 1px solid #e5e7e9;
    font-size: 14px;
    cursor: pointer;
    border-radius: 5px;
    float: left;
}

.EDI-Search-Bar {
    float: left;
    margin-left: 10px;
    width: 600px;
    border: none;
}

.EDI-Textarea {
    background-color: #000;
    color: #fff;
    resize: none;
    border-radius: 5px;
    border: 2px solid rgb(83, 0, 83);
    padding: 5px;
    box-sizing: border-box;
}

.EDI-Members-Container {
    display: inline-block;
}

.EDI-Member-Container {
    float: left;
    background-color: rgb(83, 0, 83);
    color: #fff;
    border: 1pt solid #fff;
    border-radius: 5px;
    padding: 5px;
    width: 320px;
}

.EDI-Member-Section {
    float: left;
    text-align: left;
    padding: 5px;
    width: 145px;
}

.EDI-Member-Title {
    font-weight: bold;
    font-size: 12pt;
}

.EDI-Member-Value {
    font-size: 11pt;
}

.EDI-File-Container {
    display: inline-block;
    background-color: #ccc;
    width: 400px;
    border-radius: 5px;
    cursor: pointer;
}

.EDI-File-Container:hover {
    box-shadow: 0 0 8px #000;
    transition: .25s all ease;
}

.EDI-File-Icon {
    width: 40px;
    background-color: rgb(83, 0, 83);
    border: 1px solid #fff;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    float: left;
    padding: 2px;
    text-align: center;
    color: #fff;
}

.EDI-File-Text {
    font-size: 12pt;
    padding-left: 2px;
    float: left;
    font-weight: bold;
}

.EDI-File-Type {
    width: 40px;
    background-color: #0fa1ac;
    border: 1px solid #fff;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    float: right;
    padding: 2px;
    text-align: center;
    color: #fff;
}

.EDI-Button {
    width: 180px;
    border-radius: 5px;
    background-color: #0fa1ac;
    border: 1px solid #fff;
    color: #fff;
    text-align: center;
    padding: 5px;
    font-weight: bold;
    cursor: pointer;
}

.EDI-Button:hover {
    border: 1px solid #0fa1ac;
    color: #0fa1ac;
    background-color: #fff;
    transition: .25s all ease;
}
