.Care {
    position: relative;
    width: 100%;
}

.Care-Row {
    width: 100%;
    margin-top: 3px;
}

.Care-Search-Container {
    width: 90%;
    margin: 0 auto;
    margin-top: 50px;
}

.Care-Search-Label {
    font-size: 32px;
    color: #0FA1AC;
}

.Care-Search-Label i {
    margin-left: 10px;
    font-size: 18pt;
    animation: spin2 1s infinite linear;
}

.Care-Search-Outter {
    width: 90%;
    height: 40px;
    background-color: #fff;
    border: 1px solid #e5e7e9;
    border-radius: 5px;
    padding: 6px;
    font-size: 17px;
    color: #1a4255;
    display: inline-block;
}

.Care-Search-Button {
    width: 48px;
    height: 38px;
    float: right;
    background-color: #0fa1ac;
    color: #fff;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    margin-top: -6px;
    margin-right: -6px;
    padding-top: 6px;
    padding-left: 8px;
    cursor: pointer;
}

.Care-Search-Button:hover {
    background-color: rgb(83, 0, 83);
    transition: .25s all ease;
}

.Care-Search-Button i {
    font-size: 28px;
}

.Care-Search-Selector {
    width: 250px; 
    height: 26px;
    padding: 2px;
    border: 1px solid #e5e7e9;
    font-size: 14px;
    cursor: pointer;
    border-radius: 5px;
    float: left;
}

.Care-Search-Selector:hover {
    border: 2px solid #e5e7e9;
    font-size: 14px;
}

.Care-Search-Selector-Arrow {
    float: right;
    border: solid #8ba0aa;
    border-width: 0 3px 3px 0;
    padding: 3px;
    transform: rotate(45deg);
    margin: 3px;
}

.Care-Search-Bar {
    float: left;
    margin-left: 10px;
    width: calc(100% - 310px);
    border: none;
}

.Care-Search-Selections {
    position: absolute;
    width: 220px;
    height: auto;
    z-index: 2;
    padding: 2px;
    border: 1px solid #e5e7e9;
    background-color: #fff;
    border-radius: 5px;
    color: #394653;
    box-shadow: 0 3px 6px #455A64
}

.Care-Search-Selections-Row {
    border-radius: 5px;
    width: 100%;
    cursor: pointer;
}

.Care-Search-Selections-Row:hover {
    color: #fff;
    background-color: #394653;
    transition: .25s all ease;
}

.Members-Search-Results-Container {
    margin-top: 20px;
    width: 100%;
    height: auto;
    max-height: calc(100vh - 320px);
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.Members-Search-Result-Container {
    width: 400px;
    padding: 6px;
    text-align: left;
    color: #394653;
    border: 1px solid #0fa1ac;
    border-top: none;
    border-radius: 5px;
    margin-top: 15px;
    margin-left: 8px;
    margin-bottom: 5px;
    cursor: pointer;
    background-image: linear-gradient(to bottom right, #fff, #0fa1ac);
}

.Members-Search-Result-Container:hover {
    box-shadow: 5px 5px 8px #000;
}

.FHIR-Resource-Section {
    margin-top: 5px;
    margin-bottom: 5px;
}

.FHIR-Resource-Section-Title {
    position: relative;
    margin-top: -6px;
    margin-left: 5px;
    margin-right: 10px;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 5px;
    color: #0fa1ac;
}

.FHIR-Resource-Section-Label {
    font-size: 12px;
    font-weight: bold;
    margin-top: 5px;
    color: #3771be;
}

.FHIR-Resource-Section-Value {
    font-size: 16px;
    padding-top: 2px;
    color: #fff;
    font-weight: bold;
}

.FHIR-Resource-Section-Inline {
    display: inline-block;
    width: 100%;
}

.FHIR-Resource-Section-Flex {
    display: flex;
    justify-content: space-between;
    width: 100%;
    border-top: 1px solid #ccc;
}

.FHIR-Resource-Section-Float-Right {
    float: right;
    margin-right: 8px;
}

.FHIR-Resource-Section-Float-Left {
    float: right;
    margin-right: 8px;
}

.FHIR-Resource-Initial-Container {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 4px solid #3771be;
    color: #3771be;
    background-color: #fff;
    font-size: 18px;
    text-align: center;
    font-weight: bold;
}

.FHIR-Resource-Initial {
    margin-top: 8px;
    color: #0fa1ac;
}

.Member-Header-Container {
    margin-left: 25%;  
    display: flex;
    justify-content: space-around;
}

.Member-Header-Column-Group {
    float: left;
    margin-right: 10px;
}

.Member-Header-Column {
    padding: 5px;
    float: left;
}

.Member-Resource-Section-Value {
    font-size: 16px;
    padding-top: 2px;
    color: #3771be;
    font-weight: bold;
}

.Member-Active {
    color: rgb(3, 66, 3);
    font-size: 11pt;
    text-shadow: 0 0 3px #fff;
}

.Member-Inactive {
    color: #880606;
    font-size: 11pt;
    text-shadow: 0 0 3px #fff;
}

.Member-Return-Button {
    font-size: 12pt;
    color: #3771be;
    border: 1pt solid #3771be;
    border-radius: 5px;
    padding: 5px;
    cursor: pointer;
}

.Member-Return-Button:hover {
    box-shadow: 0 0 8px #3771be;
}

.Member-Table-Container {
    width: 100%;
    margin-top: 20px;
    max-height: calc(100vh - 380px);
    overflow-y: auto;
}

.Member-Table-Header {
    width: 100%;
    border-radius: 5px;
    border: 1px solid #3771be;
    display: inline-block;
    padding: 5px;
    font-weight: bold;
}

.Member-Table-Header-Name {
    float: left;
    margin-left: 5px;
}

.Member-Table-Header-Info {
    float: right;
    margin-right: 5px;
}

.Member-Table-Header-Open {
    color: #fff;
    background-color: #3771be;
}

.Member-Table-Header-Close {
    color: #3771be;
    background-color: #fff;
}

.Member-Table-Body {
    width: 100%;
    max-width: 100vh;
    max-height: 400px;
    overflow-y: auto;
    word-wrap: break-word;
}

.Member-Table-MRP-Row {
    width: 100%;
    background-color: #fff;
}

.Member-Menu-Container {
    width: 100%;
    display: inline-block;
    border-bottom: 2px solid #3771be;
}

.Member-Menu {
    float: left;
    margin-top: 20px;
    width: 200px;
    padding: 10px;
    border-top: 1px solid #3771be;
    border-left: 1px solid #3771be;
    border-right: 1px solid #3771be;
    cursor: pointer;
    font-weight: bold;
    font-size: 16pt;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
}

.Member-Menu:hover {
    box-shadow: 0 0 5px #3771be;
    text-shadow: 0 0 5px #3771be;
}

.Member-Menu-Selected {
    color: #fff;
    background-color: #3771be;
}

.Member-Menu-Not-Selected {
    color: #3771be;
    background-color: #fff;
}

.Resource-Card-Container {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
 }

.Resource-Card-Container-MRP {
    padding: 10px;
    width: 100%;
}

.Member-MRP-Row {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.Resource-Card {
    border-radius: 5px;
    border: 1px solid #000;
    padding: 5px;
    margin: 10px;
    width: 400px;
}

.Resource-Card-Row {
    width: 100%;
    display: inline-block;
    margin-top: 3px;
}

.Resource-Card-Item-Left {
    float: left;
    margin-right: 5px;
    text-align: left;
}

.Resource-Card-Item-Right {
    float: right;
    margin-left: 5px;
    text-align: right;
}

.Resource-Card-Title {
    width: 100%;
    font-size: 16pt;
    font-weight: bold;
    text-align: center;
    background-color: #3771be;
    color: #fff;
    display: inline-block;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
}

.Resource-Card-Delete {
    float: right;
    color: #880606;
    background-color: #fff;
    border: 2px solid #880606;
    padding: 2px 8px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    cursor: pointer;
    margin-left: 5px;
}

.Resource-Card-Delete:hover {
    box-shadow: 0 0 5px #000;
}

.Resource-Card-Delete-Blink {
    animation: blink .5s infinite linear;
}

.Resource-Card-Label {
    width: 100%;
    font-size: 9pt;
    font-weight: bold;
    color: #3771be;
}

.Resource-Card-Value {
    width: 100%;
    font-size: 12pt;
    font-weight: bold;
}

.Resource-Card-FHIR {
    font-size: 9pt;
    max-width: 380px;
    max-height: 300px;
    overflow: auto;
}

.Resource.Card-Button-Container {
    cursor: pointer;
}

.Resource.Card-Button-Container:hover {
    box-shadow: 0 0 8px #000;
}

.Request-Container {
    display: inline-block;
    border: 1px solid #3771be;
    border-radius: 5px;
    width: 400px;
    height: 35px;
    margin-top: 10px;
}

.Request-Button-Container {
    width: 40px;
    height: 100%;
    float: right;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-left: 1px solid #3771be;
    color: #3771be;
    text-align: center;
    font-size: 16pt;
    cursor: pointer;
}

.Request-Button-Container:hover {
    text-shadow: 0 0 3px #3771be;
    box-shadow: 0 0 5px #3771be;
}

.Request-Text {
    float: left;
    padding: 3px;
    font-size: 12pt;
    color: #3771be;
}

.Org-List-Button {
    margin: 4px 3px;
    padding: 2px;
    border-radius: 5px;
    border: 1px solid #3771be;
    float: left;
    cursor: pointer;
}

.Org-List-Container {
    position: absolute;
    top: 90px;
    z-index: 2;
}

.Org-List-Item {
    text-align: center;
    padding: 3px;
    cursor: pointer;
    border: 1px solid #fff;
    background-color: #3771be;
    color: #fff;
    margin-bottom: 2px;
    border-radius: 5px;
}

.Org-List-Item:hover {
    box-shadow: 0 0 5px #000;
}

.Org-List-Name {
    font-size: 11pt;
    font-weight: bold;
    border-bottom: 1px solid #fff;
}

.Org-List-Url {
    font-size: 8pt;
    font-style: italic;
}

.Reset-Container {
    width: 100%;
    display: flex;
    justify-content: space-around;
}

.Reset-Button-Container {
    font-size: 24pt;
    color: #3771be;
    cursor: pointer;
}

.Reset-Button-Container:hover {
    text-shadow: 0 0 8px #000;
}

.Reset-Button-Container-Spin {
    animation: spin2 1s infinite linear;
}

@keyframes blink {
    0% { box-shadow: 0 0 8px #880606 }
    50% { box-shadow: 0 0 0 #880606}
    100% { box-shadow: 0 0 8px #880606}
}

